import { useHistory } from "react-router";
import { Button, Result } from "antd";

const StripeCancel = () => {
  const history = useHistory();

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Result
        status="warning"
        title="Subscription Payment Canceled"
        subTitle={
          <>
            <p>It seems you canceled your subscription payment. If this was a mistake, you can try again.</p>
            <p style={{ marginTop: "15px" }}>
              If you require further assistance, please contact our support team -{" "}
              <a href="mailto:info@manyfastscan.com" style={{ color: "#1890ff" }}>
                info@manyfastscan.com
              </a>
            </p>
          </>
        }
        extra={[
          <Button
            type="primary"
            key="retry"
            onClick={() => {
              history.push("/billing");
            }}
          >
            Retry
          </Button>,
          <Button
            key="home"
            onClick={() => {
              history.push("/");
            }}
          >
            Go to Homepage
          </Button>,
        ]}
      />
    </div>
  );
};
export default StripeCancel;
