import { useHistory } from "react-router";
import { Button, Result } from "antd";
import AccountHook from "../../hooks/account";

const StripeSuccess = ({ accountData }) => {
  const history = useHistory();

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Result
        status="success"
        title="Successfully subscribed to MFS Plan!"
        subTitle="Your subscription was successful. You can now enjoy all the features of the MFS Plan you subscribed to."
        extra={[
          <Button
            type="primary"
            key="home"
            onClick={() => {
              // if the success is loaded after a checkout when the user logged in through checkout only
              // then we have to force the user to relogin to get the proper JWT token
              if (accountData?.is_checkout) {
                // navigate to dashboard (hard reload)
                window.location.href = "/";
                return;
              }
              history.push("/");
            }}
          >
            Go to Homepage
          </Button>,
        ]}
      />
    </div>
  );
};
export default AccountHook(StripeSuccess);
