import { useEffect, useState } from "react";
import { Switch, BrowserRouter } from "react-router-dom";

import { ConfigProvider, Spin } from "antd";
import { isEmpty } from "./_dash";
import { getAuthUserDetail, loginForCheckoutUser } from "./actions";

import { NON_LOGIN_ROUTES, LOGGED_IN_ROUTES } from "./routes";
import CacheBuster from "react-cache-buster";

import AccountHook from "./hooks/account";
import RouteWithSubRoutes from "./components/routeWithSubRoutes";
import responseErrorService from "./utilities/responseErrorHandling";
import { AUTHORIZATION_KEY, DEVICE_ID } from "./global_constants";

const appVersion = require("../package.json").version;

const App = ({ accountData, setAccountData }) => {
  const isLoggedIn = !isEmpty(accountData);

  const [loading, setLoading] = useState(true);
  const [appRoutes, setAppRoutes] = useState(NON_LOGIN_ROUTES);

  useEffect(() => {
    getAuthUserDetail()
      .then((response) => {
        setAccountData(response);
      })
      .catch((err) => {
        // this gets triggered often, we might need to handle this differently
        // responseErrorService.handle(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!loading) {
      if (isLoggedIn) {
        setAppRoutes(LOGGED_IN_ROUTES);
      } else {
        setAppRoutes(NON_LOGIN_ROUTES);
      }
    }
  }, [isLoggedIn, loading]);

  if (loading) {
    return <Spin />;
  }

  return (
    <CacheBuster
      currentVersion={appVersion}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Spin />} //If not pass, nothing appears at the time of new version check.
    >
      <ConfigProvider
        theme={{
          components: {
            Segmented: {
              itemSelectedBg: "#1677ff",
              itemSelectedColor: "#ffffff",
            },
          },
        }}
      >
        <BrowserRouter>
          <Switch>
            {appRoutes.map((route, i) => {
              return <RouteWithSubRoutes key={i} {...route} />;
            })}
          </Switch>
        </BrowserRouter>
      </ConfigProvider>
    </CacheBuster>
  );
};

export default AccountHook(App);
