const userTypeLookup = {
  a: "Admin",
  u: "AccountAdmin",
  e: "Employee",
};

const userTypeDisplayValueLookup = {
  a: "App Global Admin",
  u: "Account Administrator",
  e: "Employee",
};

export const WEIGHT_UNITS = [
  {
    value: "1",
    label: "lbs",
  },
  {
    value: "2",
    label: "oz",
  },
];

export const IN_PACKAGING_OPTIONS = [
  {
    value: 0,
    label: "No",
  },
  {
    value: 1,
    label: "Yes",
  },
];
export const IS_ASSEMBLY_REQUIRED_OPTIONS = [
  {
    value: 0,
    label: "No",
  },
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 2,
    label: "N/A",
  },
];

export const IS_RECEIVED_OPTIONS = [
  {
    value: 0,
    label: "No",
  },
  {
    value: 1,
    label: "Yes",
  },
];

export const SHIPPING_OPTION_OPTIONS = [
  {
    value: "No",
    label: "No",
  },
  {
    value: "Yes",
    label: "Yes",
  },
];

export const IS_DAMAGED_OPTIONS = [
  {
    value: 0,
    label: "No",
  },
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 2,
    label: "Unknown",
  },
];

export const IS_MISSING_PARTS_OPTIONS = [
  {
    value: 0,
    label: "No",
  },
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 2,
    label: "Unknown",
  },
];

export const IS_FUNCTIONAL_OPTIONS = [
  {
    value: 0,
    label: "No",
  },
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 2,
    label: "Unknown",
  },
  {
    value: 3,
    label: "Unable To Test",
  },
  {
    value: 4,
    label: "N/A",
  },
];

export const SUPPORT_TICKET_PRIORITY = [
  {
    value: "Low",
    label: "Low",
  },
  {
    value: "Medium",
    label: "Medium",
  },
  {
    value: "High",
    label: "High",
  },
];

export const SUPPORT_TICKET_TYPE = [
  {
    value: "Feedback",
    label: "Feedback",
  },
  {
    value: "Access",
    label: "Access",
  },
  {
    value: "Maintenance",
    label: "Maintenance",
  },
  {
    value: "Bug",
    label: "Bug",
  },
  {
    value: "Feature",
    label: "Feature",
  },
  {
    value: "Service",
    label: "Service",
  },
  {
    value: "Incident",
    label: "Incident",
  },
];

export const MANIFEST_ITEM_DOWNLOAD_TYPES = [
  {
    value: "ManifestWithPhotos",
    label: "Manifest with Photos",
  },
  {
    value: "ManifestOnly",
    label: "Manifest Only",
  },
  {
    value: "FeaturedOnly",
    label: "Featured Items",
  },
];

export const CONVERT_TO_CURRENCY_TYPES = [
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "CAD",
    label: "CAD",
  },
  {
    value: "GBP",
    label: "GBP",
  },
];

export const UPLOADED_MANIFEST_ITEM_TRANSFER_ADD_MULITPLE_MODE = [
  {
    value: "AddQuantity",
    label: "Single Manifest Item with Quantity set to number of units",
  },
  {
    value: "AddMultiple",
    label: "Multiple Manifest Items with Quantity set to 1",
  },
];

export const ITEM_MODE_INVENTORY = "Inventory";

export const GetUserType = (type) => {
  return userTypeLookup[type];
};

export const GetUserTypeDisplayValue = (type) => {
  return userTypeDisplayValueLookup[type];
};

export const TOTAL_DOWNLOAD_PROGRESS_KEY = "TOTAL_PROGRESS";
export const SINGLE_MANIFEST_DOWNLOAD_PROGRESS_KEY = "SINGLE_MANIFEST_PROGRESS";

export const MANIFEST_UPLOAD_TRANSFER_TO_MANIFEST_DURATION_MINUTES = 2;

export const ITEM_FIELD_CONFIG_USER_DISPLAYABLE_NAME_MAP = {
  title: "Title",
  ean: "EAN",
  gtin: "GTIN",
  elid: "ELID",
  description: "Description",
  brand: "Brand",
  model: "Model",
  color: "Color",
  size: "Size",
  dimension: "Dimension",
  weight: "Weight",
  currency: "Currency",
  lowest_recorded_price: "Lowest Recorded Price",
  highest_recorded_price: "Highest Recorded Price",
  images: "Images",
  offers: "Offers",
  user_data: "User Data",
  offerprice: "Retail Price",
  unspecified_condition_sidebar_price: "Unspecified Condition Sidebar Price",
  buy_new_right_top_sidebar: "Buy New Right Top Sidebar",
  buy_used_right_top_sidebar: "Buy Used Right Top Sidebar",
  other_sellers_on_amazon_price: "Other Sellers on Amazon Price",
  new_and_used_price: "New and Used Price",
  list_amazon_price: "List Amazon Price",
  official_amazon_price: "Official Amazon Price",
  asin: "ASIN",
  offersource: "Source",
  offer_sale_price: "Offer Sale Price",
  lpn: "LPN",
  fnsku: "FNSKU",
  original_title: "Original Title",
  m_length: "Manually Entered Sizing Info - Length",
  m_width: "Manually Entered Sizing Info - Width",
  m_height: "Manually Entered Sizing Info - Height",
  m_weight: "Manually Entered Sizing Info - Weight",
  m_dimension: "Manually Entered Sizing Info - Dimension",
  m_weight_unit: "Manually Entered Sizing Info - Weight Unit",
  vendor_code: "Vendor Code",
  m_size: "Manually Entered Sizing Info - Size",
  estimate_price: "Estimate Price",
  is_damaged: "Is Damaged",
  damage_description: "Damage Description",
  in_packaging: "In Packaging",
  is_assembly_required: "Is Assembly Required",
  star_rating: "Star Rating",
  is_missing_parts: "Is Missing Parts",
  missing_parts_description: "Missing Parts Description",
  is_functional: "Is Functional",
  is_received: "Is Received",
  inventory_aisle: "Inventory Aisle",
  inventory_shelf: "Inventory Shelf",
  inventory_row: "Inventory Row",
  bucket: "Bucket",
  auction_starting_price: "Auction Starting Price",
  auction_reserve_price: "Auction Reserve Price",
  location: "Location",
  cost: "Cost",
  shipping_rate: "Shipping Rate",
  shipping_option: "Shipping Option",
  discount_sale_price: "Discount Price",
  department: "Department",
  notes: "Notes",
  category: "Category",
  product_condition: "Condition",
  auction_name: "Auction Name",
  upc_hibid_format: "UPC Hibid Format",
  price: "Price",
  lot: "Lot",
  qty: "Quantity",
  condition: "Condition",
  upc: "UPC",
  username: "User Name",
  rasmus_photos: "Rasmus Photos",
  image_links: "Image Links",
  auction_item_id: "Manifest Item ID",
  user_defined_field_one: "User Defined Field (1)",
  user_defined_field_two: "User Defined Field (2)",
  user_defined_field_three: "User Defined Field (3)",
  user_defined_field_four: "User Defined Field (4)",
  user_defined_field_five: "User Defined Field (5)",
  user_defined_field_six: "User Defined Field (6)",
  user_defined_field_seven: "User Defined Field (7)",
  user_defined_field_eight: "User Defined Field (8)",
  user_defined_field_nine: "User Defined Field (9)",
  user_defined_field_ten: "User Defined Field (10)",
  user_defined_field_eleven: "User Defined Field (11)",
  user_defined_field_twelve: "User Defined Field (12)",
  user_defined_field_thirteen: "User Defined Field (13)",
  user_defined_field_fourteen: "User Defined Field (14)",
  user_defined_field_fifteen: "User Defined Field (15)",
  user_defined_field_sixteen: "User Defined Field (16)",
  user_defined_field_seventeen: "User Defined Field (17)",
  user_defined_field_eighteen: "User Defined Field (18)",
  user_defined_field_nineteen: "User Defined Field (19)",
  user_defined_field_twenty: "User Defined Field (20)",
  user_defined_field_twenty_one: "User Defined Field (21)",
  user_defined_field_twenty_two: "User Defined Field (22)",
  user_defined_field_twenty_three: "User Defined Field (23)",
  user_defined_field_twenty_four: "User Defined Field (24)",
  user_defined_field_twenty_five: "User Defined Field (25)",
  event_id: "Event ID",
  product_type: "Product Type",
  is_sold: "Is Sold?",
  vendor_id: "Vendor",
  delivery_load_id: "Delivery Load",
  inventory_group_id: "Inventory Group",
  manifest_group_id: "Manifest Group",
  user_created_by_id: "Created By",
  number_of_items: "Number of Items",
  pallet_number: "Pallet Number",
  condition_id: "Condition",
  department_id: "Department",
  category_id: "Category",
  shelf_id: "Shelf",
  premium_price: "Premium Price",
  bin_id: "Bin",
  created_date: "Created Date",
  updated_date: "Updated Date",
  building_id: "Building",
  outgoing_truck_id: "Outgoing Truck",
  retail_price: "Retail Price",
  created_at: "Created At",
  modified_at: "Modified At",
  load_status: "Load Status",
  is_closed: "Is Closed?",
  location_id: "Location",
  name: "Name",
  destination: "Destination",
  sale_price: "Sale Price",
  load_date: "Load Date",
  depart_date: "Depart Date",
  total_pallet_cost: "Total Pallet Cost",
  total_item_cost: "Total Item Cost",
  zone_id: "Zone",
  product_cost: "Product Cost",
  user_first_last: "User Name",
  freight_cost: "Freight Cost",
  number_of_pallets: "Number of Pallets",
  load_started_at: "Load Started At",
  load_finished_at: "Load Finished At",
  delivery_company: "Delivery Company",
  delivery_person: "Delivery Person",
  retailer_item_url: "Retailer Item URL",
  ebay_id: "eBay ID",
  ebay_reference_id: "eBay Reference ID",
  internal_sku: "Internal SKU",
  category_external_id: "Category External ID",
  department_external_id: "Department External ID",
  sort_bucket: "Sort Bucket",
};

export const CONDITION_FILTER_TYPE = [
  "Please Select",
  "Character Length Greater Than",
  "Character Length Less Than",
  "Contains",
  "Less Than",
  "Greater Than",
  "Equals",
];

export const CONDITION_FILTER_AVAILABLE_FIELDS = ["Title", "Description", "Price", "Notes", "Category"];

export const EBAY_PRODUCT_CONDITION_ID_USER_DISPLAYABLE_NAME_MAP = {
  1000: "New",
  1500: "New other (see details)",
  1750: "New with defects",
  2000: "Manufacturer refurbished",
  2500: "Seller refurbished",
  3000: "Used",
  4000: "Very Good",
  5000: "Good",
  6000: "Acceptable",
  7000: "For parts or not working",
};

export const ECOMMERCE_QUEUE_STATUS = {
  0: "Pending",
  1: "Processing",
  2: "Error",
  3: "Success",
  4: "Cancelled",
  5: "Relist",
  6: "Removed by User",
};

export const ECOMMERCE_STATUS_CODES = {
  PENDING: 0,
  PROCESSING: 1,
  ERROR: 2,
  SUCCESS: 3,
  CANCELLED: 4,
  RELIST: 5,
  REMOVED_BY_USER: 6,
};

export const PLATFORM_INTEGRATION_TYPE = {
  1: "Ebay",
  2: "Shopify",
  3: "Square",
  5: "Clover",
};

export const PLATFORM_TYPE_CODES = {
  EBAY: 1,
  SHOPIFY: 2,
  SQUARE: 3,
  CLOVER: 5,
};

export const PLATFORM_STATUS = [
  { value: 1, label: "Live" },
  { value: 0, label: "Sandbox" },
];
export const CLOVER_SETTINGS_FIELDS = [
  "clover_default_discount_type",
  "clover_default_discount_value",
  "clover_is_taxable_item_test",
  "clover_is_taxable_item_live",
];
export const SHOPIFY_SETTINGS_FIELDS = ["default_discount_type_for_shopify", "default_discount_amount_for_shopify"];
export const SQUARE_SETTINGS_FIELDS = [
  "default_discount_amount_for_square",
  "default_discount_type_for_square",
  "default_square_location_live",
  "default_square_location_test",
];
export const INTEGRATION_SETTINGS_FIELDS = [...CLOVER_SETTINGS_FIELDS, ...SHOPIFY_SETTINGS_FIELDS, ...SQUARE_SETTINGS_FIELDS];

export const LISTING_PRICE_TYPES = {
  RETAIL_PRICE: 1,
  DISCOUNTED_SALE_PRICE: 2,
};

export const BOOLEAN_FIELDS_EXCLUDE_IF_NO_OPTIONS = [
  "is_damaged",
  "is_functional",
  "is_missing_parts",
  "is_assembly_required",
  "in_packaging",
  "is_received",
];

export const DOWNLOAD_FIELD_VARIABLES = [
  {
    variable: "{title}",
    name: "Title",
    description: "The title of the Manifest Item.",
  },
  {
    variable: "{upc}",
    name: "UPC",
    description: "The UPC assigned to the Manifest Item.",
  },
  {
    variable: "{lot}",
    name: "Lot Number",
    description: "The lot number associated with the Manifest Item.",
  },
  {
    variable: "{manifest_name}",
    name: "Manifest Name",
    description: "The name of the manifest this item belongs to.",
  },
  {
    variable: "{section}",
    name: "Section",
    description: "The section for the Manifest Item.",
  },
  {
    variable: "{manifest_group_name}",
    name: "Manifest Group Name",
    description: "The name of the manifest group associated with this item.",
  },
  {
    variable: "{manifest_id}",
    name: "Manifest ID",
    description: "The unique identifier for the manifest.",
  },
  {
    variable: "{manifest_item_id}",
    name: "Manifest Item ID",
    description: "The unique identifier for the manifest item.",
  },
  {
    variable: "{retail_price}",
    name: "Retail Price",
    description: "The retail price of the Manifest Item.",
  },
  {
    variable: "{estimate_price}",
    name: "Estimate Price",
    description: "The estimated price of the Manifest Item.",
  },
  {
    variable: "{discounted_price}",
    name: "Discounted Price",
    description: "The discounted price of the Manifest Item.",
  },
  {
    variable: "{auction_starting_price}",
    name: "Auction Starting Price",
    description: "The starting price for auction listing.",
  },
  {
    variable: "{auction_reserve_price}",
    name: "Auction Reserve Price",
    description: "The reserve price set for auction listing.",
  },
  {
    variable: "{premium_price}",
    name: "Premium Price",
    description: "The premium price of the Manifest Item.",
  },
  {
    variable: "{location}",
    name: "Location",
    description: "The location of the Manifest Item.",
  },
  {
    variable: "{internal_sku}",
    name: "Internal SKU",
    description: "The internal stock-keeping unit (SKU) for tracking the item.",
  },
  {
    variable: "{created_date_format}",
    name: "Created Date",
    description: "The formatted date when the Manifest Item was created.",
  },
  {
    variable: "{created_datetime_format}",
    name: "Created Date Time",
    description: "The formatted date and time when the Manifest Item was created.",
  },
  {
    variable: "{created_date}",
    name: "Created Date",
    description: "The date when the Manifest Item was created.",
  },
  {
    variable: "{updated_date}",
    name: "Updated Date",
    description: "The date when the Manifest Item was last updated.",
  },
  {
    variable: "{mg_start_date}",
    name: "Manifest Group Start Date",
    description: "The start date of the manifest group.",
  },
  {
    variable: "{mg_end_date}",
    name: "Manifest Group End Date",
    description: "The end date of the manifest group.",
  },
  {
    variable: "{size}",
    name: "Size",
    description: "The size of the Manifest Item.",
  },
  {
    variable: "{m_size}",
    name: "Manually Entered Sizing Info - Size",
    description: "Manually entered sizing info for the Manifest Item.",
  },
];
